<template>
  <div>
    <Exercise
      v-for="exercise in cardData.exercises"
      :key="exercise.id"
      :exercise="exercise"
      :exerciseData="exerciseData[exercise.id]"
      summaryMode="true"
    />
  </div>
</template>

<script>
import Exercise from "./Exercise.vue";
export default {
  components: { Exercise },
  props: ["cardData", "exerciseData"],
};
</script>

<style>
</style>