<template>
  <v-container>
    <h1 class="font-weight-light">Podsumowanie</h1>
    <v-card class="mt-2">
      <v-card-title>Twój wynik:</v-card-title>
      <v-card-text>
        <v-progress-linear
          color="green"
          height="20"
          :value="(this.currentScore / this.points) * 100"
          ><strong
            >{{ Math.round((this.currentScore / this.points) * 100) }}%</strong
          ></v-progress-linear
        >
      </v-card-text>
    </v-card>
    <SummaryCard
      v-for="card in cards"
      :key="card.nr"
      :cardData="card"
      :exerciseData="exerciseData"
      class="mt-2"
    />

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      transition="slide-y-transition"
      top
      color="success"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle-outline</v-icon>
        <v-layout column>
          <div>
            <strong>Udało Ci się!</strong>
          </div>
          <div>
            Zrobiłeś(aś) już wszystkie ćwiczenia z tego zestawu!
          </div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <!-- CHECKING OPEN EXERCISES -->
    <v-dialog v-model="checkDialog" width="500" persistent>
      <v-card>
        <v-stepper v-model="checkingData.current" vertical>
          <div v-for="(exercise, index) in checkingData.exercises" :key="index">
            <v-stepper-step
              :complete="checkingData.current > index + 1"
              :step="index + 1"
            >
              Sprawdź zadanie z kluczem
            </v-stepper-step>

            <v-stepper-content :step="index + 1">
              <div class="mb-2" v-html="exercise.title"></div>
              <v-textarea
                readonly
                filled
                v-model="exercise.answer"
              ></v-textarea>

              <v-card color="grey lighten-4 mt-2 mb-2" elevation="0">
                <v-card-title>Klucz</v-card-title>
                <v-card-text>
                  <p v-html="exercise.key"></p>
                </v-card-text>
              </v-card>

              <v-text-field
                type="number"
                v-model="pointsToAdd"
                label="Liczba punktów, które sobie przyznajesz"
                :placeholder="'0 - ' + exercise.points"
                outlined
                class="mt-4"
              ></v-text-field>

              <v-btn color="primary" @click="nextStep" class="mt-2">
                Dalej
              </v-btn>
            </v-stepper-content>
          </div>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import SummaryCard from "../components/LearningMode/SummaryCard.vue";
import db from "@/firebase/init";
import firebase from "firebase";

export default {
  components: { SummaryCard },
  props: ["id"],
  computed: {
    exerciseData() {
      return this.$store.state.exerciseData;
    },
    cards() {
      return this.$store.state.exercises;
    },
    points() {
      return this.$store.state.points;
    },
    score() {
      return this.$store.state.score;
    },
    containOpenExercises() {
      return this.$store.state.containOpenExercises;
    },
    openExercises() {
      return this.$store.state.openExercises;
    },
    uid() {
      return this.$store.state.uid;
    },
  },
  destroyed() {
    this.clearExerciseData();
  },
  data() {
    return {
      snackbar: true,
      // check
      checkDialog: false,
      checkingData: {
        all: 0,
        current: 1,
        points: 0,
        exercises: [],
      },
      pointsToAdd: "",
      currentScore: 0,
    };
  },
  methods: {
    ...mapMutations(["clearExerciseData"]),
    addDataToFirestore(isScore = true) {
      if (isScore) {
        db.collection("logs")
          .doc()
          .set({
            score: Math.round((this.currentScore / this.points) * 100),
            id: this.id,
            uid: this.uid,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
      } else {
        db.collection("logs")
          .doc()
          .set({
            id: this.id,
            uid: this.uid,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    },
    reRenderMath() {
      if (window.MathJax) {
        console.log("rendering mathjax");
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
    nextStep() {
      if (
        parseInt(this.pointsToAdd) >= 0 &&
        parseInt(this.pointsToAdd) <=
          this.checkingData.exercises[this.checkingData.current - 1].points
      ) {
        for (let index = 0; index < this.pointsToAdd; index++) {
          this.currentScore++;
        }

        console.log(this.checkingData.current);
        console.log(this.checkingData.exercises.length);

        if (this.checkingData.current == this.checkingData.exercises.length) {
          this.addDataToFirestore();
          this.checkDialog = false;
        }

        this.checkingData.current++;

        this.pointsToAdd = "";
      } else {
        alert(
          "Podaj liczbę od 0 do " +
            this.checkingData.exercises[this.checkingData.current - 1].points
        );
      }
      this.reRenderMath();
    },
  },
  mounted() {
    this.$nextTick(function() {
      if (
        isNaN(Math.round((this.score / this.points) * 100)) == false ||
        this.containOpenExercises == true
      ) {
        this.currentScore = this.score;
        if (this.containOpenExercises == true) {
          // we should ask user check open exercises
          // for (let i = 0; i < this.openExercises.length; i++) {}
          this.checkDialog = true;
          this.checkingData.exercises = this.openExercises;
        } else {
          // no open exercises, we can continue
          this.addDataToFirestore();
        }
      } else {
        this.addDataToFirestore(false);
        alert(
          "Zadanie wykonane! Dziękujemy! \n\nMożesz teraz zamknąć to okno."
        );
      }

      this.reRenderMath();

      // log events
      firebase.analytics().logEvent("done_exercise", { id: this.id });
    });
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
